@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Track */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}


@font-face {
  font-family: 'population_zero_bbregular';
  src: url('./populationzerobb-webfont.woff2') format('woff2'),
       url('./populationzerobb-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

#modalDefaultPopup .title, #modalDefaultPopup .subtitle, #modalDefaultPopup .btn-quick, #modalDefaultPopup .lnk-option  {
  font-family: 'population_zero_bbregular'; 
  letter-spacing: 1px; 
}
#modalDefaultPopup .btn-quick, #modalDefaultPopup .subtitle {
  font-size: 48px;  text-shadow: 3px 3px #000;
}
#modalDefaultPopup .btn-quick {
  font-size: 32px;  
}
#modalDefaultPopup .title {
  font-size: 64px;  
  text-shadow: 3px 3px #000;
  line-height: 56px;
}

#modalDefaultPopup .lnk-option {
  font-size: 18px;  
  text-shadow: 2px 2px #000;
}

#modalDefaultPopup {
  font-family: "Radio Canada", sans-serif;
  font-optical-sizing: auto;
  font-weight:normal;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}